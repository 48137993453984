<template>
    <div
        class="home"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">{{ title }}</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <img
                            class="top-icon-img"
                            :src="$img.email"
                        />
                        <p class="accont-slogin">{{ title }}</p>
                    </div>
                </div>
                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :rules="rules"
                        :model="accountForm"
                        class="g-form"
                        style="width:370px;"
                    >
                        <el-form-item prop="email">
                            <el-input
                                prop="email"
                                type="email"
                                v-model="accountForm.email"
                                placeholder="请输入您的新邮箱"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <el-form-item class="btn-wrap ml-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="getEmailCode"
                                >获取验证码</el-button>
                            </div>
                        </el-form-item>

                        <el-form-item class="btn-wrap back-wrap mr-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="$router.back(-1)"
                                >返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "validEmail",
    metaInfo() {
        return {
            title: "绑定邮箱 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            title: '', // 显示标题
            accountForm: {
                email: "", //密码
                zma: "", //验证码
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: "请输入邮箱",
                        trigger: ["change", "blur"]
                    },
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["change", "blur"]
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //获取邮箱验证码
        getEmailCode() {
            let _this = this;
            _this.loading = true;
            _this.post("/user/user/get_email_code", { email: _this.accountForm.email }, data => {
                _this.loading = false;
                if (data.code == 200) {
                    this.$message({
                        message: "验证码已发送到您的邮箱",
                        type: "success"
                    });
                    _this.$router.push({
                        path: "/user/setting/valid_mail",
                        query: { email: _this.accountForm.email }
                    });
                } else {
                    _this.$message.error(data.msg);
                    return;
                }
            });
        },
    },
    created() {
        this.title = this.$route.query.title;
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


